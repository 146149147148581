<form (submit)="save()" [formGroup]="form">
    <div class="flex items-center" mat-dialog-title>
        <h2 class="headline m-0 flex-auto">{{ isEdit ? 'Edycja użytkownika' : 'Nowy użytkownik' }}</h2>

        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon svgIcon="mat:close"></mat-icon>
        </button>
    </div>

    <mat-divider class="text-border"></mat-divider>

    <mat-dialog-content class="flex flex-col">
        <mat-form-field class="mt-6">
            <mat-label>Adres email</mat-label>
            <input cdkFocusInitial formControlName="username" matInput required>

            <mat-icon matPrefix svgIcon="mat:email"></mat-icon>
            <mat-error *ngIf="form.get('username').hasError('required')">Adres email jest wymagany</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Imię</mat-label>
            <input formControlName="firstName" matInput required>

            <mat-icon matPrefix svgIcon="mat:person"></mat-icon>
            <mat-error *ngIf="form.get('firstName').hasError('required')">Imię jest wymagane</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Nazwisko</mat-label>
            <input formControlName="lastName" matInput required>

            <mat-icon matPrefix svgIcon="mat:person"></mat-icon>
            <mat-error *ngIf="form.get('lastName').hasError('required')">Nazwisko jest wymagane</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Telefon</mat-label>
            <input formControlName="phone" matInput>

            <mat-icon matPrefix svgIcon="mat:phone"></mat-icon>
            <mat-error *ngIf="form.get('phone').hasError('required')">Numer telefonu jest wymagany</mat-error>
            <mat-error *ngIf="form.get('phone').hasError('isDigit')">Numer telefonu musi zawierać wyłącznie cyfry
            </mat-error>
            <mat-error *ngIf="form.get('phone').hasError('minlength') || form.get('phone').hasError('maxlength')">
                Numer telefonu musi zawierać 9 cyfr
            </mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Role</mat-label>
            <mat-select formControlName="authorities" [compareWith]="compareRole" required>
                <mat-option *ngFor="let role of roles" [value]="[role]">{{ role.name }}</mat-option>
            </mat-select>
            <mat-icon matPrefix svgIcon="mat:lock"></mat-icon>
            <mat-error *ngIf="form.get('authorities').hasError('required')">Wybierz rolę</mat-error>
        </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions *ngIf="!isArchived()" align="end">
        <button [disabled]="isSubmitted" mat-button type="button" (click)="close(false)">Anuluj</button>
        <button [disabled]="isSubmitted" color="primary" mat-flat-button>Zapisz</button>
    </mat-dialog-actions>
</form>
