<div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">{{getTitle()}}</h2>

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
        <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
</div>

<mat-divider class="text-border"></mat-divider>
<mat-dialog-content class="flex flex-col">
    <p>{{getMessage()}}</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button type="button" (click)="close(false)">Nie</button>
    <button color="primary" mat-flat-button type="button" (click)="close(true)">Tak</button>
</mat-dialog-actions>