import {NavigationItem} from "../../@vex/interfaces/navigation-item.interface";

export const navigation: NavigationItem[] = [
    {
        type: 'subheading',
        label: 'Dashboards',
        children: [
            {
                type: 'link',
                label: 'Strona główna',
                route: '/',
                icon: 'mat:insights',
                routerLinkActiveOptions: {exact: true}
            },
            {
                type: 'link',
                label: 'Klienci',
                route: '/clients',
                icon: 'mat:groups',
                routerLinkActiveOptions: {exact: true}
            }
        ]
    },
    {
        type: 'subheading',
        label: 'Rekrutacja',
        children: [
            {
                type: 'link',
                label: 'Oferty pracy',
                route: '/recruitment/job-offers',
                icon: 'mat:group',
                routerLinkActiveOptions: {exact: true}
            }
        ]
    },
    {
        type: 'subheading',
        label: 'Admin',
        children: [
            {
                type: 'link',
                label: 'Użytkownicy',
                route: '/users',
                icon: 'mat:group',
                routerLinkActiveOptions: {exact: true}
            },
            {
                type: 'link',
                label: 'Pracownicy',
                route: '/employees',
                icon: 'mat:group',
                routerLinkActiveOptions: {exact: true}
            },
            {
                type: 'link',
                label: 'Nieobecności',
                route: '/absences',
                icon: 'mat:south_west',
                routerLinkActiveOptions: { exact: true }
            },
            {
                type: 'link',
                label: 'Projekty',
                route: '/projects',
                icon: 'mat:dataset',
                routerLinkActiveOptions: { exact: true }
            },
            {
                type: 'link',
                label: 'Delegacje',
                route: '/delegations',
                icon: 'mat:flight_takeoff',
                routerLinkActiveOptions: {exact: true}
            },
            {
                type: 'dropdown',
                label: 'Ustawienia',
                icon: 'mat:settings',
                children: [
                    {
                        type: 'dropdown',
                        label: 'Ogólne',
                        children: [
                            {
                                type: 'link',
                                label: 'Moja Firma',
                                route: '/my-company/basic-info',
                                routerLinkActiveOptions: {exact: true}
                            }
                        ]
                    },
                    {
                        type: 'dropdown',
                        label: 'Pracownicy',
                        icon: 'mat:settings',
                        children: [
                            {
                                type: 'link',
                                label: 'Stanowiska',
                                route: '/company-settings/positions',
                                routerLinkActiveOptions: {exact: true}
                            },
                            {
                                type: 'link',
                                label: 'Działy',
                                route: '/company-settings/departments',
                                routerLinkActiveOptions: {exact: true}
                            },
                        ]
                    }
                ]
            },
        ]
    }
];
