import {AbstractControl, FormControl} from "@angular/forms";
import {
    HAS_DIGIT_REGEX,
    IS_DIGIT_REGEX,
    LOWER_CASE_REGEX,
    SPECIAL_CHAR_REGEX,
    UPPER_CASE_REGEX
} from "./validators.const";

export class CustomValidator {
    public static identicalField(controlName: string, controlToCompareName: string) {
        return (controls: AbstractControl) => {
            const control = controls.get(controlName);
            const checkControl = controls.get(controlToCompareName);

            if (control.hasError('identicalField')) {
                delete control.errors['identicalField'];
            }

            if (checkControl?.value.length === 0) {
                return null;
            }

            if (control?.value !== checkControl?.value) {
                checkControl.setErrors({identicalField: true, ...control.errors});
                return {identicalField: true};
            } else {
                return null;
            }
        };
    }

    public static firstLowerThanSecond(controlName: string, controlToCompareName: string) {
        return (controls: AbstractControl) => {
            const control = controls.get(controlName);
            const checkControl = controls.get(controlToCompareName);

            if (control.value) {
                if (!checkControl.value) {
                    return null;
                }

                if (control.value >= checkControl.value) {
                    control.setErrors({firstLowerThanSecond: true, ...control.errors});
                    return {firstLowerThanSecond: true};
                } else if (control.hasError('firstLowerThanSecond')) {
                    const errors = control.errors;
                    delete errors['firstLowerThanSecond'];
                    Object.keys(errors).length === 0 ? control.setErrors(null) : control.setErrors(errors);
                    return null;
                }
            }
            return null;
        };
    }

    public static firstDateLowerThanSecond(controlName: string, controlToCompareName: string) {
        return (controls: AbstractControl) => {
            const control = controls.get(controlName);
            const checkControl = controls.get(controlToCompareName);

            if (control.value) {
                const controlValue: Date | null = typeof control.value === 'string' ? new Date(control.value) : control.value;

                if (!checkControl.value) {
                    return null;
                }

                const checkControlValue: Date | null = typeof checkControl.value === 'string' ? new Date(checkControl.value) : checkControl.value;

                if (controlValue >= checkControlValue) {
                    control.setErrors({firstLowerThanSecond: true, ...control.errors});
                    checkControl.setErrors({firstLowerThanSecond: true, ...checkControl.errors});
                    return {firstLowerThanSecond: true};
                } else {
                    if (control.hasError('firstLowerThanSecond')) {
                        const errors = control.errors;
                        delete errors['firstLowerThanSecond'];
                        Object.keys(errors).length === 0 ? control.setErrors(null) : control.setErrors(errors);
                        return null;
                    }

                    if (checkControl.hasError('firstLowerThanSecond')) {
                        const errors = checkControl.errors;
                        delete errors['firstLowerThanSecond'];
                        Object.keys(errors).length === 0 ? checkControl.setErrors(null) : checkControl.setErrors(errors);
                        return null;
                    }
                }


            }
            return null;
        };
    }

    public static upperCase(control: FormControl) {
        const hasUpper = UPPER_CASE_REGEX.test(control.value);
        if (!hasUpper) {
            return {upperCase: true};
        }
        return null;
    }

    public static lowerCase(control: FormControl) {
        const hasLower = LOWER_CASE_REGEX.test(control.value);
        if (!hasLower) {
            return {lowerCase: true};
        }
        return null;
    }

    public static hasDigit(control: FormControl) {
        const hasDigit = HAS_DIGIT_REGEX.test(control.value);
        if (!hasDigit) {
            return {hasDigit: true};
        }
        return null;
    }

    public static isDigit(control: FormControl) {
        const isDigit = IS_DIGIT_REGEX.test(control.value);
        if (!isDigit) {
            return {isDigit: true};
        }
        return null;
    }

    public static special(control: FormControl) {
        const hasSpecial = SPECIAL_CHAR_REGEX.test(control.value);
        if (!hasSpecial) {
            return {special: true};
        }
        return null;
    }

    public static containsOtherFieldValue(controlName: string, controlToCompareName: string) {
        return (controls: AbstractControl) => {
            const control = controls.get(controlName);
            const checkControl = controls.get(controlToCompareName);

            if (control.hasError('containsOtherFieldValue')) {
                delete control.errors['containsOtherFieldValue'];
            }

            if (checkControl?.value.length === 0) {
                return null;
            }

            if (control?.value.includes(checkControl?.value)) {
                control.setErrors({containsOtherFieldValue: true, ...control.errors});
                return {containsOtherFieldValue: true};
            } else {
                return null;
            }
        };
    }
}
