import {Injectable} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {IUser, NewUser} from "../model/user.model";
import {CustomValidator} from "../../validators/custom-validator";

type UserFormGroupContent = {
    id: FormControl<IUser['id'] | NewUser['id']>;
    username: FormControl<IUser['username']>;
    firstName: FormControl<IUser['firstName']>;
    lastName: FormControl<IUser['lastName']>;
    phone: FormControl<IUser['phone']>;
    authorities: FormControl<IUser['authorities']>;
}

export type UserFormGroup = FormGroup<UserFormGroupContent>;

@Injectable({
    providedIn: 'root'
})
export class UserFormService {
    createUserFormGroup(user: IUser = {id: null}) {
        return new FormGroup<UserFormGroupContent>({
            id: new FormControl(
                {value: user.id, disabled: true},
                {
                    nonNullable: true,
                    validators: [Validators.required],
                },
            ),
            username: new FormControl(user.username,
                {validators: [Validators.required, Validators.email]}
            ),
            firstName: new FormControl(user.firstName, {
                    validators: [Validators.required]
                }
            ),
            lastName: new FormControl(user.lastName, {
                    validators: [Validators.required]
                }
            ),
            phone: new FormControl(user.phone, {
                    validators: [
                        Validators.required,
                        Validators.minLength(9),
                        Validators.maxLength(9),
                        CustomValidator.isDigit
                    ]
                }
            ),
            authorities: new FormControl(user.authorities || [], {
                    validators: [Validators.required]
                }
            )
        });
    }

    getUser(form: UserFormGroup): IUser | NewUser {
        return form.getRawValue() as IUser | NewUser;
    }

    resetForm(form: UserFormGroup, user: IUser) {
        form.reset(
            {
                ...user,
            } as any
        );

        if (user.archived) {
            form.disable();
        }
    }
}
