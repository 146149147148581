import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {ApiService} from "../../service/api.service";
import {IUser, NewUser} from "../model/user.model";

@Injectable({
  providedIn: 'root'
})
export class UserService extends ApiService<IUser> {
  private readonly usersEndpoint = '/users';
  public paginatedList(params?: any): Observable<IUser[]> {
    return this.getPaginated(this.usersEndpoint, params);
  }

  public getUser(userId: number) {
    return this.getById(this.usersEndpoint, userId);
  }

  public addUser(user: IUser | NewUser) {
    return this.post(this.usersEndpoint, user);
  }

  public updateUser(user: IUser) {
    return this.update(this.usersEndpoint, user.id, user);
  }

  public patchUser(user: IUser) {
    return this.partialUpdate(this.usersEndpoint, user.id, user);
  }

  public archiveUser(user: IUser) {
    return this.archive(this.usersEndpoint, user.id, user);
  }

  public restoreUser(user: IUser) {
    return this.restore(this.usersEndpoint, user.id, user);
  }

  compareUser(o1: Pick<IUser, 'id'> | null, o2: Pick<IUser, 'id'> | null): boolean {
    return o1 && o2 ? this.getUserIdentifier(o1) === this.getUserIdentifier(o2) : o1 === o2;
  }

  private getUserIdentifier(user: Pick<IUser, 'id'>): number {
    return user.id;
  }
}
